<template>
  <div>
    <b-card class="mb-1">
      <b-form class="m-0" ref="formFilter" @submit.prevent="getData()">
        <div class="form-row">
          <b-col cols="12" md="4">
            <div class="form-group">
              <label for="">Turma</label>
              <input type="tel" v-model="filters.turma" class="form-control" />
            </div>
          </b-col>
          <b-col cols="12" md="1">
            <button
              type="submit"
              class="btn btn-primary btn-block"
              style="margin-top: 20px"
            >
              <div v-if="loading.filter">
                <b-spinner label="Loading..." small />
              </div>
              <div v-else>
                <feather-icon
                  icon="SearchIcon"
                  class="cursor-pointer cursor"
                  size="16"
                />
              </div>
            </button>
          </b-col>
        </div>
      </b-form>
    </b-card>

    <b-card no-body v-if="!loading.filter" class="mb-0">
      <div class="m-2">
        <b-form-row class="align-items-center justify-content-end">
          <!-- Select dropdown -->
          <b-col cols="4" md="2" class="d-none d-sm-block">
            <v-select
              label="title"
              required
              v-model="filters.perPage"
              :options="filters.perPageOptions"
              @input="setPerPageSelected"
              :clearable="false"
            />
          </b-col>

          <b-col cols="12" md="5" class="d-flex justify-content-center">
            <b-form-input
              type="search"
              placeholder="Pesquisar..."
              id="filterInput"
              v-model="filter"
            />
          </b-col>

          <b-col cols="6" md="2" offset-md="1" class="d-flex justify-content-end">
            <button
              class="btn btn-block btn-yellow"
              @click="syncItems"
              :disabled="loading.sync || !selectedItems.length"
            >
              <b-spinner v-if="loading.sync" small variant="primary" class="mr-50" />
              <feather-icon v-else icon="RepeatIcon" class="mr-50" />
              Sincronizar
            </button>
          </b-col>

          <b-col cols="6" md="2" class="d-flex justify-content-end">
            <b-button
              variant="primary"
              @click="toggleSelectAll"
              class="btn-block"
            >
              {{ isAllSelected ? "Deselecionar Todos" : "Selecionar Todos" }}
            </b-button>
          </b-col>
        </b-form-row>
      </div>

      <b-table
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="fields"
        primary-key="id"
        show-empty
        table-class="table-row-padding"
        empty-text="Nenhum registro encontrado"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <!-- Checkbox column -->
        <template #cell(selected)="data">
          <b-form-checkbox
            v-model="data.item.selected"
            @change="updateSelectedItems"
          />
        </template>

        <template #cell(status_name)="data">
          <b-badge class="d-block" :variant="`light-${data.item.status_class}`">
            {{ data.item.status_name }}
          </b-badge>
        </template>

        <template #cell(items)="data">
          <b-badge class="d-block" :variant="`light-${data.item.items ? 'success' : 'warning'}`">
            <strong>{{ data.item.items }}</strong>
          </b-badge>
        </template>

        <template #cell(action)="data">
          <feather-icon
            icon="EyeIcon"
            size="16"
            class="cursor-pointer cursor"
            @click="openModal(data.item)"
          />
        </template>
      </b-table>

      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page="filters.currentPage"
        :per-page="filters.perPage"
        :total-rows="totalRows"
      />
    </b-card>

    <b-card v-else class="mb-0">
      <div class="row">
        <div class="col-md-12 text-center">
          <p class="my-0 py-3 d-flex-center">
            <b-spinner small variant="primary" class="mr-50" /> Buscando
            pedidos...
          </p>
        </div>
      </div>
    </b-card>

    <b-modal
      id="viewOrderModal"
      ref="orderModal"
      scrollable
      centered
      hide-footer
      title="JSON Consys"
      @hide="clearSelectedOrder"
    >
      <pre v-if="selectedOrder.json_consys">
        {{ formattedJson }}
      </pre>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BIcon,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BTable,
  BButton,
  BPagination,
  BMedia,
  BTooltip,
  BBadge,
  BFormRow,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    flatPickr,
    CustomPaginateTable,
    BCard,
    BFormRow,
    BForm,
    BIcon,
    BRow,
    BCol,
    BFormInput,
    BSpinner,
    BTable,
    BButton,
    BPagination,
    BMedia,
    BTooltip,
    BBadge,
    BFormCheckbox,
    vSelect,
  },
  data() {
    return {
      isAllSelected: false,
      filters: {
        currentPage: 1,
        perPage: 100,
        turma: "",
      },
      filter: null,
      filterOn: [],
      totalRows: 0,
      rowsTable: 0,
      dataItems: [],
      selectedOrder: {}, // Armazena os dados do item selecionado
      selectedItems: [],
      loading: {
        sync: false,
        filter: false,
      },
      fields: [
        {
          key: "selected",
          label: "",
          class: "text-center",
        },
        {
          key: "id",
          label: "ID",
          class: "text-left",
        },
        {
          key: "items",
          label: "Itens",
          class: "text-center",
        },
        {
          key: "customer_name",
          label: "Cliente",
          class: "text-left",
        },
        {
          key: "status_name",
          label: "Status",
          class: "text-center",
        },
        {
          key: "customer_cpf",
          label: "CPF",
          class: "text-center",
        },
        {
          key: "customer_email",
          label: "E-mail",
          class: "text-center",
        },
        {
          key: "num_turma",
          label: "Num Turma",
          class: "text-center",
        },
        {
          key: "created_at",
          label: "Data",
          class: "text-center",
        },
        {
          key: "action",
          label: "",
          class: "text-center",
          thStyle: "width: 80px",
        },
      ],
      perPageOptions: [
        {
          title: "100",
          value: 100,
        },
        {
          title: "200",
          value: 200,
        },
      ],
    };
  },
  methods: {
    toggleSelectAll() {
      this.isAllSelected = !this.isAllSelected;

      this.dataItems.forEach((item) => {
        item.selected = this.isAllSelected;
      });

      this.updateSelectedItems();
    },
    openModal(item) {
      this.selectedOrder = item;
      this.$refs.orderModal.show();
    },
    clearSelectedOrder() {
      this.selectedOrder = {};
    },
    updateSelectedItems() {
      this.selectedItems = this.dataItems.filter((item) => item.selected);
      this.isAllSelected = this.selectedItems.length === this.dataItems.length;
    },
    submitFilter() {
      this.getData();
    },
    setPerPageSelected(obj) {
      this.filters.perPage = obj.value;
    },
    onFiltered(filteredItems) {
      this.metaTotal = filteredItems.length;
      this.filters.currentPage = 1;
    },
    syncItems() {
      this.$swal({
        title: "Sincronizar produtos em massa",
        text: "Com base no JSON enviado pelo Consys, verificaremos as turmas na Seducar e adicionaremos ao pedido caso 1 ou mais sejam encontradas.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sincronizar",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading.sync = true;
          const uuids = this.selectedItems.map((item) => item.uuid);
        
          this.$store.dispatch("Order/fetchSyncJsonByUuids", uuids)
          .then(() => {
            this.selectedItems = [];
            this.$swal({
              icon: "success",
              title: "Produtos sincronizados!",
              text: `${successes.length} produto(s) foram sincronizados com sucesso.`,
              customClass: {
                confirmButton: "btn btn-success",
              },
            });

            this.getData()
          })
          .finally(() => {
            this.loading.sync = false;
          });
        }
      });
    },

    async getData() {
      this.loading.filter = true;

      let filtersSearch = {
        currentPage: this.filters.currentPage,
        perPage: this.filters.perPage,
        turma: this.filters.turma ?? "",
        checkConsys: true,
      };

      this.$store
        .dispatch("Order/all", filtersSearch)
        .then((orders) => {
          if (orders) {
            this.dataItems = orders.data;
            this.rowsTable = orders.data.length;
            this.totalRows = orders.meta.total;
            this.filters.currentPage = orders.meta.current_page;
          }
        })
        .finally(() => {
          this.loading.filter = false;
        });
    },
  },
  computed: {
    formattedJson() {
      return this.selectedOrder.json_consys
        ? JSON.stringify(JSON.parse(this.selectedOrder.json_consys), null, 2)
        : "";
    },
  },
  directives: {
    Ripple,
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
    this.$root.$off("open:export-table");
  },
  mounted() {
    this.getData();

    this.$root.$on("paginate:update", (res) => {
      this.filters.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.filters.perPage = res._perPage;
      this.getData();
    });
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.swal2-icon-content {
  i {
    font-size: 2rem;
    color: #28c76f;
  }
}

.swal2-icon-show {
  border-color: #28c76f;
}
</style>
